import { css, default as styled } from "styled-components";

import { BreakPointsMap, media } from "../GlobalStyle/media";

export const ResumeWrap = styled.div`
  max-width: ${BreakPointsMap.xlarge}em;
  width: 100%;
  margin: 0 auto;
`;

export const ResumeGrid = styled.div`
  margin: 5em 3em;
  display: flex;
  flex-direction: column;

  ${media.mediumLarge`
    display: grid;
    grid-gap: 3.2rem;
    grid-template-columns: 6em calc(100% - 6em - 3.2rem);
  `}
  ${media.large`
    grid-template-columns: 6em calc(100% - 21em - 6.4rem) 15em;
  `}
`;

export const Sidebar = styled.div`
  order: 4;
  grid-column: 1 / 1;

  ${media.mediumLarge`
    grid-column: 2 / 2;
  `}

  ${media.large`
    order: unset;
    grid-column: auto;
  `}
`;

export const Contain = styled.div`
  grid-column: 1 / 1;

  ${media.mediumLarge`
    grid-column: 2 / 2;
  `}
`;

export const DownloadContain = styled(Contain)`
  order: 4;
`;

export const SectionGrid = styled.div`
  display: grid;
  grid-gap: 3.2rem;
  grid-template-columns: 100%;
  grid-column: 1 / 3;

  ${media.mediumLarge`
    grid-template-columns: 6em calc(100% - 6em - 3.2rem);
  `}
`;

export const Headline = styled.h1`
  font-weight: 700;
  font-size: 4.2rem;
  line-height: 3.6rem;
  margin: 5rem 0 0 0;
  display: flex;
`;

export const SubHeadline = styled.h2`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-top: 2rem;
`;

export const Paragraph = styled.p`
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-top: 1rem;
`;

export const Subhead = styled.h3`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 3.2rem;
`;

export const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const Logo = styled.img`
  display: flex;
  height: 8em;
  width: max-content;
`;

interface IPositionedCell {
  position?: number;
  span?: number;
  heightSpan?: number;
}

// tslint:disable: no-magic-numbers
export const PositionedCell = styled.div<IPositionedCell>`
  grid-column-start: ${({ position }) => (position ? position : "auto")};

  ${({ span }) =>
    span &&
    css`
      grid-column-end: span 1;

      ${media.mediumLarge`
        grid-column-end: ${span > 2 ? "span 3" : `span ${span}`};
      `}
      ${media.large`
        grid-column-end: ${span > 3 ? "span 4" : `span ${span}`};
      `}
    `}
  ${({ heightSpan }) =>
    heightSpan &&
    css`
      grid-row-start: 1;

      ${media.mediumLarge`
        grid-row-end: ${heightSpan};
      `}
    `}
`;
// tslint:enable: no-magic-numbers

export const Link = styled.a`
  font-style: italic;
  color: ${({ theme: { link } }) => link.colorLink};

  &:hover {
    color: ${({ theme: { link } }) => link.colorLinkHover};
  }
`;

interface ISection {
  $left?: boolean;
}

export const Section = styled.h3<ISection>`
  white-space: nowrap;
  background: ${({ theme: { section } }) => section.colorBackground};
  color: ${({ theme: { section } }) => section.colorText};
  align-self: baseline;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 3.2rem;
  margin: 4em 0 0;

  ${({ $left }) => css`
    ${media.mediumLarge`
      margin: 0;
      text-align: ${$left ? "left" : "right"};
      justify-self: flex-end;
    `}
  `}
`;

export const RightCell = styled.div`
  margin-bottom: 3.2rem;
`;

export const CellHeader = styled.h2`
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 3.2rem;
`;

export const CellCaption = styled.span`
  font-weight: 400;
`;

interface IListBullet {
  $hideBullet?: boolean;
}

export const ListBullet = styled.ul<IListBullet>`
  list-style: none;
  color: ${({ theme: { text } }) => text.colorText};
  margin: ${({ $hideBullet }) => ($hideBullet ? "0" : "3.2rem 1em 0")};
  font-size: 1.6rem;
  line-height: 3.2rem;
`;

export const ListElement = styled.li`
  position: relative;
  list-style: none;
  font-size: 1.6rem;
  line-height: 3.2rem;
  color: ${({ theme: { text } }) => text.colorText};
`;

export const BulletElement = styled(ListElement)`
  &::before {
    content: "\\2022";
    position: absolute;
    color: ${({ theme }) => theme.text.colorText};
    left: -0.75em;
    font-size: 1.5rem;
  }
`;
