import * as React from "react";

import logo from "../../Resources/images/logo.jpg";

import {
  BulletElement,
  CellHeader,
  SectionGrid,
  Headline,
  Link,
  ListBullet,
  ListElement,
  Logo,
  PositionedCell,
  ResumeWrap,
  RightCell,
  Section,
  Subhead,
  ResumeGrid,
  CellCaption,
  Sidebar,
  DownloadContain,
  HeaderBlock,
  SubHeadline,
  // Paragraph,
} from "./presentational";

const Header: React.FC = () => (
  <>
    <Logo src={logo} alt="logo" />
    <HeaderBlock>
      <Headline>Kevin Faherty</Headline>
      <SubHeadline>
        I’m Kevin, a US-based software engineer with over seven years of
        experience. I specialize in building web applications with dashboards
        and component libraries.
      </SubHeadline>
      {/* <Paragraph>cool</Paragraph> */}
    </HeaderBlock>
  </>
);

const WorkExperience: React.FC = () => (
  <>
    <div>
      <CellHeader>
        Senior Front End Engineer <CellCaption>at</CellCaption> Findem
      </CellHeader>
      <Subhead>
        Redwood City, CA. April 2022 - March 2023.{" "}
        <Link href="https://findem.ai">Website</Link>
      </Subhead>
      <ListBullet>
        <BulletElement>
          Senior engineer on next generation of talent acquisition search
          platform built with Typescript, React, and Redux.
        </BulletElement>
        <BulletElement>
          Authored central frameworks for folksonomy attribute search and
          display of 750M+ candidate profiles.
        </BulletElement>
        <BulletElement>
          Established type definitions, engineering best practices, testing
          suites, ci pipelines, and success metrics.
        </BulletElement>
        <BulletElement>
          Facilitated intense 2 month migration from legacy jQuery application
          to React platform with feature parity.
        </BulletElement>
        <BulletElement>
          Addressed performance bottlenecks and escalations to reduce latency by
          40% and scale platform to 180+ clients.
        </BulletElement>
        <BulletElement>
          Performed code review and mentoring of four offshore developers new to
          React and modern Typescript.
        </BulletElement>
      </ListBullet>
    </div>
    <div>
      <CellHeader>
        Senior Engineer <CellCaption>at</CellCaption> CancerIQ
      </CellHeader>
      <Subhead>
        Chicago, IL. June 2020 - March 2022.{" "}
        <Link href="https://www.canceriq.com/">Website</Link>
      </Subhead>
      <ListBullet>
        <BulletElement>
          Lead engineer on cancer prevention web application built with
          Typescript, React, Node, and GraphQL.
        </BulletElement>
        <BulletElement>
          Created federated api gateway service to serve mock and production
          data using Node and Apollo Server.
        </BulletElement>
        <BulletElement>
          Consolidated fragmented legacy angular products into single React
          application supporting 200+ hospitals.
        </BulletElement>
        <BulletElement>
          Led product design of application workflows and accessible,
          mobile-first responsive design system with Figma.
        </BulletElement>
        <BulletElement>
          Executed performance and security requirements to embed application in
          customer EHR with FHIR.
        </BulletElement>
        <BulletElement>
          Worked alongside principal engineer on microservice rearchitecture and
          tech-debt roadmapping.
        </BulletElement>
      </ListBullet>
    </div>
    <div>
      <CellHeader>
        Senior Software Engineer <CellCaption>at</CellCaption> Avant/Amount
      </CellHeader>
      <Subhead>
        Chicago, IL. February 2018 - May 2020.{" "}
        <Link href="https://amount.com">Webite</Link>
      </Subhead>
      <ListBullet>
        <BulletElement>
          Lead engineer on CRM, customer dashboards, and partner portal built
          with Typescript, React, and GraphQL.
        </BulletElement>
        <BulletElement>
          Launched loan and credit card servicing dashboards for 50M+ customers
          using AWS Lambda and Serverless.
        </BulletElement>
        <BulletElement>
          Overhauled global styling pipeline with themeable component library to
          enable 10+ white-label partnerships.
        </BulletElement>
        <BulletElement>
          Introduced multi-tenant partner portal with sensitive financial
          compliance modules and external integrations.
        </BulletElement>
        <BulletElement>
          Modernized internal CRM with Typescript and GraphQL to eliminate prod
          issues for 600+ call center agents.
        </BulletElement>
        <BulletElement>
          Led front end launch of point-of-sale loan servicing modules with
          three team members.
        </BulletElement>
      </ListBullet>
    </div>
    <div>
      <CellHeader>
        Javascript Developer <CellCaption>at</CellCaption> Level Trading
        Field/Riskbone
      </CellHeader>
      <Subhead>Chicago, IL. March 2016 - October 2017. </Subhead>
      <ListBullet>
        <BulletElement>
          Engineer on workspace-based commodities trading platform built with
          Backbone, SCSS, jQuery UI, and Node.
        </BulletElement>
        <BulletElement>
          Streamed real-time exchange data to present market prices, depth,
          position, orders, audit logs, and risk.
        </BulletElement>
        <BulletElement>
          Produced modules for displaying aggregated news headlines and live and
          historical market data charts with D3.
        </BulletElement>
        <BulletElement>
          Designed core frameworks for desktop-like window ui,
          publish–subscribe, payment processing, and caching.
        </BulletElement>
        <BulletElement>
          Created python services for parsing 60+ external financial reports,
          benchmarks, and indices published daily.
        </BulletElement>
        <BulletElement>
          Developed CMS dashboard for authoring articles, creating module store
          listings, viewing analytics, and more.
        </BulletElement>
      </ListBullet>
    </div>
    <div>
      <CellHeader>Freelance Web Developer</CellHeader>
      <Subhead>Milwaukee, WI. August 2015 - April 2016.</Subhead>
      <ListBullet>
        <BulletElement>
          Freelance engineer working with clients on mobile/desktop designs and
          websites built with Angular, Sass, and PHP.
        </BulletElement>
      </ListBullet>
    </div>
    <div>
      <CellHeader>
        Graphic Designer, Web Developer <CellCaption>at</CellCaption> Radyne
      </CellHeader>
      <Subhead>Milwaukee, WI. February 2014 - October 2015.</Subhead>
      <ListBullet>
        <BulletElement>
          Designer creating marketing content in-house for print and web with
          Photoshop, InDesign, Angular and CSS.
        </BulletElement>
      </ListBullet>
    </div>
  </>
);

const Projects: React.FC = () => (
  <>
    <div>
      <CellHeader>Hero's Playbook</CellHeader>
      <Subhead>Cloud tabletop character sheets. 2023.</Subhead>
      <ListBullet>
        <BulletElement>
          Web application for playing tabletop RPGs online built with React,
          Typescript, GraphQL subscriptions, Node, and MongoDB.
        </BulletElement>
        <BulletElement>
          Features real-time die rolls, messaging between players, and character
          sheet template editing to support specialized game mechanics with
          robust widgets.
        </BulletElement>
      </ListBullet>
    </div>
    <div>
      <CellHeader>TweetBox</CellHeader>
      <Subhead>
        Twitter client. 2017.{" "}
        <Link href="https://github.com/kfaherty/tweets-React">Github</Link>
      </Subhead>
      <ListBullet>
        <BulletElement>
          Twitter app that featured OAuth authentication with Twitter, real-time
          streaming timelines, favorites, retweets, mentions, search, direct
          messages, and profiles built with React, Socket.io, and Node.
        </BulletElement>
      </ListBullet>
    </div>
    {/* <div>
      <CellHeader>Supervisitor</CellHeader>
      <Subhead>
        Jam game. 2017.{" "}
        <Link href="https://mattdonatelli.itch.io/supervisitor">Website</Link>
      </Subhead>
      <ListBullet>
        <BulletElement>
          3D first person exploration game made in 48 hours for the{" "}
          <Link href="https://itch.io/jam/bscotch100">
            Butterscotch ShenaniJam
          </Link>{" "}
          made in Unity using C# with Matt Donatelli and Zach Fendelman.
        </BulletElement>
        <BulletElement>
          Ranked #6 overall by user ratings out of 130 entries.
        </BulletElement>
      </ListBullet>
    </div> */}
  </>
);

const Education: React.FC = () => (
  <div>
    <CellHeader>Milwaukee Institute of Art and Design</CellHeader>
    <Subhead>Milwaukee, WI. Graduated December 2013.</Subhead>
    <ListElement>Bachelor of arts - Emphasis in illustration</ListElement>
  </div>
);

const Contact: React.FC = () => (
  <RightCell>
    <Section $left={true}>Contact</Section>
    <ListBullet $hideBullet={true}>
      <ListElement>hello@kevinfaherty.com</ListElement>
      <ListElement>
        <Link href="https://github.com/kfaherty">
          https://github.com/kfaherty
        </Link>
      </ListElement>
    </ListBullet>
  </RightCell>
);

const FrontEndTech: React.FC = () => (
  <RightCell>
    <Section $left={true}>Front End Web Technologies</Section>
    <ListBullet $hideBullet={true}>
      <ListElement>Typescript, ESnext</ListElement>
      <ListElement>Javascript</ListElement>
      <ListElement>React, Next.js</ListElement>
      <ListElement>Apollo GraphQL</ListElement>
      <ListElement>Redux, Redux Query </ListElement>
      <ListElement>Styled-components</ListElement>
      <ListElement>MaterialUI, AntDesign </ListElement>
      <ListElement>Sass, CSS, Storybook </ListElement>
      <ListElement>WCAG, accessibility</ListElement>
      <ListElement>SVGs, CSS animation, D3</ListElement>
      <ListElement>WebSockets, Socket.io</ListElement>
      <ListElement>Semantic HTML, SEO</ListElement>
      <ListElement>Angular, Backbone</ListElement>
      <ListElement>jQuery, jQuery UI</ListElement>
    </ListBullet>
  </RightCell>
);

const BackEndTech: React.FC = () => (
  <RightCell>
    <Section $left={true}>Back End Web Technologies</Section>
    <ListBullet $hideBullet={true}>
      <ListElement>Node, Express, Apollo Server</ListElement>
      <ListElement>REST, GraphQL</ListElement>
      <ListElement>Postgres, SQL</ListElement>
      <ListElement>Redis, MongodB</ListElement>
      <ListElement>Google cloud</ListElement>
      <ListElement>AWS Lambda, Serverless, S3 </ListElement>
      <ListElement>Python</ListElement>
      <ListElement>Ruby on Rails, PHP</ListElement>
    </ListBullet>
  </RightCell>
);
const Tooling: React.FC = () => (
  <RightCell>
    <Section $left={true}>Developer Tooling</Section>
    <ListBullet $hideBullet={true}>
      <ListElement>Webpack, Babel</ListElement>
      <ListElement>Jest, Enzyme, ESLint</ListElement>
      <ListElement>Visual regression, Cypress, Playwright</ListElement>
      <ListElement>Jenkins, ci/cd pipelines, Docker</ListElement>
      <ListElement>Google Stackdriver, Sentry</ListElement>
      <ListElement>Jira, agile methodology</ListElement>
      <ListElement>Git, Github, SVN</ListElement>
      <ListElement>Figma, Photoshop, Wireframing</ListElement>
    </ListBullet>
  </RightCell>
);
const Interests: React.FC = () => (
  <RightCell>
    <Section $left={true}>Interests</Section>
    <ListBullet $hideBullet={true}>
      <ListElement>Front end engineering </ListElement>
      <ListElement>Human computer interaction </ListElement>
      <ListElement>User interface design </ListElement>
      <ListElement>Component libraries</ListElement>
      <ListElement>Web applications</ListElement>
      <ListElement>Illustration</ListElement>
    </ListBullet>
  </RightCell>
);

const Download: React.FC = () => (
  <DownloadContain>
    <a href="/resume-kevin-faherty.pdf">resume (PDF)</a>
  </DownloadContain>
);

const Resume: React.FC = () => (
  <ResumeWrap>
    <ResumeGrid>
      <Header />
      <SectionGrid>
        <PositionedCell heightSpan={7}>
          <Section>Experience</Section>
        </PositionedCell>
        <WorkExperience />
      </SectionGrid>
      <Sidebar>
        <Contact />
        <FrontEndTech />
        <BackEndTech />
        <Tooling />
        <Interests />
      </Sidebar>
      <SectionGrid>
        <PositionedCell>
          <Section>Education</Section>
        </PositionedCell>
        <Education />
      </SectionGrid>
      <SectionGrid>
        <PositionedCell heightSpan={4}>
          <Section>Projects</Section>
        </PositionedCell>
        <Projects />
      </SectionGrid>
      <Download />
    </ResumeGrid>
  </ResumeWrap>
);

export default Resume;
