import baseStyled, { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  typography: {
    bodyTypeface: "verlag",
  },
  text: {
    colorText: "#232525",
  },
  section: {
    colorBackground: "transparent",
    colorText: "#232525",
  },
  link: {
    colorLink: "#232525",
    colorLinkHover: "#232525",
  },
  globalAnchorDecoration: "underline",
  outline: "1px dotted #232525",
} as const;

export type Theme = typeof theme;
export const styled = baseStyled;
